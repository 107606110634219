import React from "react";
import DefaultLayout from "./components/layouts/DefaultLayout";

function App() {
    return (
        <div className="App">
            <DefaultLayout>
                <div>test</div>
            </DefaultLayout>
        </div>
    );
}

export default App;
