import React from "react";
import { Card, Container, Row, Col, Jumbotron } from "react-bootstrap";
import { ReactComponent as Developer } from "../assets/developer.svg";
import { ReactComponent as GV } from "../assets/gv.svg";
import { ReactComponent as HK } from "../assets/hk.svg";
import { ReactComponent as NU } from "../assets/nu.svg";
import { ReactComponent as PON } from "../assets/pon.svg";

export const MainSection = () => {
    return (
        <main>
            <Container fluid="md">
                <Row>
                    <Col>
                        <Jumbotron fluid className="transparent">
                            <Container>
                                <Developer className="developers" />
                                <h1 className="text-white">Freelance software developer</h1>
                            </Container>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col>
                        <Card className="transparent card">
                            <GV />
                        </Card>
                    </Col>
                    <Col>
                        <Card className="transparent card">
                            <PON />
                        </Card>
                    </Col>
                    <Col>
                        <Card className="transparent card">
                            <NU />
                        </Card>
                    </Col>
                    <Col>
                        <Card className="transparent card">
                            <HK />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </main>
    );
};

export default MainSection;
