import React from "react";
import Header from "../Header";
import MainSection from "../MainSection";

interface Props {
    children: React.ReactNode;
}

export const DefaultLayout = ({ children }: Props) => {
    return (
        <>
            <Header />
            <MainSection />
        </>
    );
};

export default DefaultLayout;
