import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { ReactComponent as Logo } from "../assets/logo.svg";

export const Header = () => {
    return (
        <header>
            <Navbar collapseOnSelect expand="lg" variant="dark" sticky="top">
                <Navbar.Brand href="#home">
                    <Logo className="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link href="https://www.linkedin.com/in/bassel-al-araaj-89626468" target="_blank">
                            LinkedIn
                        </Nav.Link>
                        <Nav.Link href="mailto:info@bassel.dev">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
};

export default Header;
